import React, {Component} from 'react'
import {SpinnerContext} from './Spinner'
import PropTypes from 'prop-types'

class ResultBlock extends Component{
    static contextType=SpinnerContext;
    render(){
        const {answers} = this.context;
        let summ = answers.yes+answers.no;
        if(this.props.withprobably) summ+= answers.probably;
        return <div id="result-block">
            <p><span>Гаданий - {summ}</span></p>
            <div className="row">
                <div className="col-6 col-lg-12 col-md-12">
                    <p><span>Да</span> - {answers.yes} ({Math.round(100/((summ)?summ:1)*answers.yes)}%)</p>
                </div>
                <div className="col-6 col-lg-12 col-md-12">
                    <p><span>Нет</span> - {answers.no} ({Math.round(100/((summ)?summ:1)*answers.no)}%)</p>
                </div>
                {
                    (this.props.withprobably)&&
                    <div className="col-12">
                        <p><span>Наверное</span> - {answers.probably} ({Math.round(100/((summ)?summ:1)*answers.probably)}%)</p>
                    </div>
                }
            </div>
        </div>;
    }

}

ResultBlock.propTypes = {
    withprobably: PropTypes.bool.isRequired
};

export default ResultBlock